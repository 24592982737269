import React from "react";
import styled from "styled-components";
import Fade from "react-reveal";
import "./styles.css";

function Section4() {
  return (
    <Container>
      <Wrap>
        <Title>OUR VISION</Title>

        <Description>
          <p>
            Akira is an art based project, we believe that a roadmap limits the
            potential of a project and stops us from fully exploring our options
            with the projects future. We value art, art is what matters the most
            to us here at Project Akira and we believe that our art has helped
            us build such a strong and amazing community.We could do a
            tokenomics, P2E Game, Staking promise etc but we want to be honest
            with you and let you know where the project is headed. We will NOT
            be promising anything unrealistic like that and would once again
            like to say that we are an ART based project.
          </p>

          <p>
            Since art is our main focus of the project, we plan to continue
            giving quality art to the holders in the form of Custom NFT Manga.
          </p>
        </Description>
        <Description></Description>

        <Title>MANGA</Title>
        <Description>
          <p>
            Post launch, we will be delivering manga to the holders in the form
            of an NFT. We have an elaborate plot in which each and every one of
            our holders will have their NFTs in the storyline. It will be high
            quality art which is issued to the holders on a monthly basis.
            Creating custom manga isn't an easy process, we expect it to take
            some time but we guarantee that it will be flawless.
          </p>

          <p>
            There will be two perspectives to the story, Pure or Corrupted. So
            the read will be worth your while. Each chapter will hold an
            exciting plotpoint and continuation of the story! The manga will be
            dropping monthly to the holders till the story reaches its end.
          </p>
        </Description>
        <Title>POST MINT COMMUNITY</Title>
        <Description>
          <p>
            The holders of our NFT will be granted access to an alpha group with
            a lot of active perks and potential whitelist winning opportunities
            for projects. The holders will be guaranteed an ML spot for any
            future projects related to this collection. The community will
            continue to grow as we market our collection slowly and steadily,
            the possibilities are endless.
          </p>

          <p>
            We strive on making a project built by us and controlled by the
            holders. We will consider all feedback by our holders and shape the
            project the way the holders want.
          </p>
        </Description>
        <Title>-AKIRA</Title>
      </Wrap>
    </Container>
  );
}

export default Section4;

const Container = styled.section`
  // min-height: 100vh;
  background-color: #000;
  background-repeat: no-repeat;
  background-size: 100%;
  // min-width: 100vw;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Wrap = styled.div`
  margin: 50px 0 100px 0;
  // margin-top: 64px;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  max-width: 951px;
  width: 100%;
  min-width: 260px;
`;

const Title = styled.div`
  font-family: ChineseRock;
  font-style: normal;
  font-weight: 500;
  font-size: 48px;
  /* identical to box height, or 36px */
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 32px 0;
  margin-bottom: 21px;
  color: #cf152d;

  @media (max-width: 1100px) {
    font-size: 32px;
  }
`;

const Description = styled.p`
  // max-width: 951px;
  // // max-height: 88px;
  // width: 100%;
  margin: 0 32px;
  height: 100%;
  // min-width: 200px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0em;
  /* or 22px */

  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;

  @media (max-width: 1100px) {
    font-size: 14px;
  }
`;

const ProgressWrap = styled.div`
  margin: 64px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
`;

const Phase = styled.section`
  display: flex;
  max-width: 951px;
  width: 100%;
  margin-bottom: 30px;
`;

const Left = styled.div`
  margin-top: 32px;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 600px) {
    display: none;
  }
`;

const Box = styled.div`
  width: 40px;
  height: 40px;
  border: ${(props) =>
    props.height !== "0%" ? "3px solid #FFFFFF" : "3px solid #FFFFFF66"};
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Rect = styled.div`
  width: 16px;
  height: 16px;
  background: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Stick = styled.div`
  margin-top: 16px;
  position: relative;
  width: 6px;
  height: 220px;
  background: rgba(255, 255, 255, 0.25);
`;

const Progress = styled.div`
  position: absolute;
  width: 100%;
  height: ${(props) => props.height};
  // height: 60%;
  background: #ffffff;
`;

const Right = styled.div`
  padding: 20px 32px;
  margin: 0 16px;
  height: fit-content;
  max-width: 951px;
  width: 100%;
  border: 3px solid
    ${(props) => (props.height !== "0%" ? "#ffffff" : "#ffffff66")};

  ul {
    li {
      font-family: Poppins;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
    }
  }
`;

const Name = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  color: ${(props) =>
    props.height !== "0%" ? "#9000ff" : "rgba(255, 255, 255, 0.4)"};
`;

const Desc = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Content = styled.div`
  margin: 24px 0;
  max-width: 702px;
  width: 100%;
  min-width: 100px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: ${(props) => (props.height !== "0%" ? "#FFFFFF" : "#FFFFFF66")};
`;

const Team = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 64px 0;
  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  img {
    transition: 0.3s;

    :hover {
      width: 300px;
      height: 300px;
    }
    width: 280px;
    height: 280px;
  }
  @media (max-width: 1000px) {
    margin: 32px 32px;
  }
`;

const MemberName = styled.div`
  font-family: Poppins;
  margin-top: 16px;
  margin-bottom: 6px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #9000ff;
`;

const Position = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  color: #ffffff;
  max-width: 200px;
  text-align: center;
`;
