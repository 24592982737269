import React from "react";
import "./styles.css";
import styled from "styled-components";
function AboutUs() {
  return (
    <>
      <Wrap>
        <video className="loopanim" autoPlay muted loop>
          <source
            className="video-loop"
            src="./assests/loopanim.mp4"
            type="video/mp4"
          />
        </video>
        <Body>
          <Title>About Us</Title>
          <Description>
            <p>
              Akira is a collection of 2222 celestials inhabiting the solana
              landscape. The celestials, angelic beings that dwelled in heaven,
              were content with their master and creator, the Supreme.
            </p>
            <p>
              The Supreme loved the celestials, but humanity fascinated him
              more. While some of the celestials reveled in their master’s glee
              at Humanity’s accomplishments, others grew jealous and yearned for
              their master’s gaze. To catch the Supreme’s gaze, a jealous
              celestial slew one of their own kind, eager to catch the eye of
              their creator.
            </p>
            <p>
              The Supreme, disappointed in his own creations, cast out all the
              celestials, hoping that they would learn from the toils of
              Humanity.
            </p>
            <p>
              And from the heavens, the celestials descended into the futuristic
              landscape of CyberTokyo city of Akira
            </p>
          </Description>
        </Body>
      </Wrap>
    </>
  );
}

export default AboutUs;
const Body = styled.div`
  margin: 0px 32px;
  // margin-top: 64px;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;

  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  width: 700px;
  @media (max-width: 800px) {
    margin: 128px 0px;

    position: relative;
    top: 350px !important;
    display: flex !important;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 1024px) {
    margin: 128px 0px;

    position: relative;
    top: 120px;
    display: flex !important;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`;
const Wrap = styled.div`
  position: relative;
  background-color: black;
`;
const Title = styled.div`
  margin: 0 32px;
  font-family: Stranger;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  /* identical to box height, or 36px */
  display: flex;
  justify-content: center;
  text-align: center;

  margin-bottom: 36px;
  color: #cf152d;
  @media (max-width: 1024px) {
    margin: 0 10px;
    margin-bottom: 16px;
    font-size: 80px;
  }
`;

const Description = styled.p`
  margin: 0 32px;
  height: 100%;

  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0em;
  /* or 22px */

  text-align: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;

  @media (max-width: 1024px) {
    // margin: 0 10px;
    // width: 300px;
    font-size: 14px;
    text-align: center;
    padding: 0 20px 0 20px;
  }
`;
