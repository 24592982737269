import React from "react";
import styled from "styled-components";

function Section6() {
  return (
    <Container>
      <Wrap>
        {/* <a href="/">
          <img src="assests/Logo.png" alt="" />
        </a> */}
        <Right>
          <Title>Join the community</Title>
          <Description>
            Project Akira Discord already has over 15000 members! If you want to
            join the community it’s here. We host games, events,etc. for our
            community and this is the best place to get any information about
            Project Akira.
          </Description>
          <a class="connect_btn" href="">
            <Join>
              {/* <DiscordImg>
                <img src="assests/Discord2.png" alt="" />
              </DiscordImg> */}
              <Text>
                <a
                  className="discord-link"
                  href="https://discord.gg/KnThpXuyHK"
                >
                  Join the Discord
                </a>
              </Text>
            </Join>
          </a>
        </Right>

        <Security>@2022 Akira. All rights reserved.</Security>
      </Wrap>
    </Container>
  );
}

export default Section6;

const Container = styled.div`
  background: #000;
  position: relative;
  bottom: 10px;
`;

const Wrap = styled.div`
  margin: 64px 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  img {
    margin: 16px 0;
    width: 150px;
  }
`;

const Desc = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin: 16px 0;
  color: #ffffff;
`;

const Social = styled.div`
  display: flex;
`;

const Box = styled.div`
  width: 36px;
  height: 36px;
  border: 2px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  transition: 0.3s;
  :hover {
    background: #cf152d !important;
  }
  img {
    height: 12px;
    width: 16px;
    border-radius: 0px;
  }
`;

const Security = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-align: center;
  color: #ffffff99;
  margin: 32px 0;
`;
const Right = styled.div`
  margin: 32px 32px;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Title = styled.div`
  margin: 0px 0;
  margin-bottom: 32px;
  font-family: Stranger;
  font-style: normal;
  font-weight: 500;
  font-size: 80px;
  color: #cf152d;
`;

const Description = styled.div`
  display: flex;
  margin-bottom: 64px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  text-align: center;
  color: #cf152d;
  @media (max-width: 1024px) {
    font-size: 14px;
  }
`;
const Join = styled.div`
  box-sizing: border-box;
  margin: 16px 0;
  text-align: center;
  background: inherit;
  display: flex;
  transition: 0.5s;
  :hover {
    background: #000;
    color: #fff !important;
  }
`;
const Text = styled.div`
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  border-radius: 10px;

  font-size: 18px;
  color: #cf152d;
  width: 100%;
  border: 2px solid #cf152d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 30px 20px 30px;
  text-align: center;
  :hover {
    color: #fff !important;
    border: 2px solid #fff;
  }
`;
