import React from "react";
import styled from "styled-components";
import "./styles.css";

function Section5() {
  return (
    <Container className="">
      <Wrap>
        <img className="footer__img" src="assests/banner.png" alt="" />
      </Wrap>
    </Container>
  );
}

export default Section5;

const Container = styled.div`
  background: #cf152d;
  position: relative;
  bottom: 8px;
`;

const Wrap = styled.div`
  display: flex;

  @media (max-width: 1300px) {
    flex-direction: column;
    width: 100%;
    height: auto;
  }
`;
