import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";

import plus from "./Frame 504.svg";
import minus from "./Frame 505.svg";

import { Grid } from "@mui/material";
import "./faq-styles.css";
const FAQ = () => {
  const [style, setStyle] = React.useState(0);

  const changeStyle = () => {
    setStyle(!style);
  };
  const [style2, setStyle2] = React.useState(0);

  const changeStyle2 = () => {
    setStyle2(!style2);
  };
  const [style3, setStyle3] = React.useState(0);

  const changeStyle3 = () => {
    setStyle3(!style3);
  };
  const [style4, setStyle4] = React.useState(0);

  const changeStyle4 = () => {
    setStyle4(!style4);
  };
  const [style5, setStyle5] = React.useState(0);

  const changeStyle5 = () => {
    setStyle5(!style5);
  };

  const [style6, setStyle6] = React.useState(0);

  const changeStyle6 = () => {
    setStyle6(!style6);
  };

  const [style7, setStyle7] = React.useState(0);

  const changeStyle7 = () => {
    setStyle7(!style7);
  };
  return (
    <div id="faq" className="faq__container">
      <header className="title2">Faq</header>

      <Grid className="grid" container alignItems="center" spacing={2}>
        <Grid style={{ textAlign: "center" }} item xs={12} md={12}>
          <Accordion
            style={{ border: "1px solid black", backgroundColor: "white" }}
          >
            <AccordionSummary
              className={style ? "cont faq" : "cont2 faq"}
              onClick={changeStyle}
              expandIcon={
                !style ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={style ? "text1" : "text2"}>
                What is Project Akira?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                <p>
                  Akira is a collection of 2222 celestials descended from the
                  heavens and stuck in time in the CyberTokyo city. ⛩️
                </p>
                <p>
                  Now venturing the Solana landscape. Akira is an art based
                  project, we value art, art is what matters the most to us here
                  at Project Akira and if you're expecting some tokenomics,
                  staking, P2E, etc this project is not for you.
                </p>
              </Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion
            style={{ border: "1px solid black", backgroundColor: "white" }}
          >
            <AccordionSummary
              className={style2 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle2}
              expandIcon={
                !style2 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style2 ? "text1" : "text2"}>
                What is the total supply?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">2222</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ border: "1px solid black", backgroundColor: "white" }}
          >
            <AccordionSummary
              className={style3 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle3}
              expandIcon={
                !style3 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style3 ? "text1" : "text2"}>
                What will be the mint price?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">1.5 Sol</Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <AccordionSummary
              className={style4 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle4}
              expandIcon={
                !style4 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style4 ? "text1" : "text2"}>
                What time is the mint?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                <p>15 February </p>
                <p>Presale time - 7pm UTC</p>
                <p>Public time - 7:30pm UTC</p>
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <AccordionSummary
              className={style5 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle5}
              expandIcon={
                !style5 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style5 ? "text1" : "text2"}>
                Which solana wallets can be used to mint?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                Phantom, Solflare and Sollet.
              </Typography>
            </AccordionDetails>
          </Accordion>

          <Accordion
            style={{ backgroundColor: "white", border: "1px solid black" }}
          >
            <AccordionSummary
              className={style6 ? "cont faq" : "cont2 faq"}
              onClick={changeStyle6}
              expandIcon={
                !style6 ? <img src={plus} alt="" /> : <img src={minus} alt="" />
              }
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className={style6 ? "text1" : "text2"}>
                What is the official mint site?
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="faq__ans">
                We will be launching on Monkelabs launchpad.
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </div>
  );
};

export default FAQ;
