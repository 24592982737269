import React from "react";
import AboutUs from "../components/Home/AboutUs";
import FAQ from "../components/Home/FAQ";
import Section1 from "../components/Home/Section1";
import Section2 from "../components/Home/Section2";
import Section3 from "../components/Home/Section3";
import Section3Carousel from "../components/Home/Section3Carousel";
import Section4 from "../components/Home/Section4";
import Section5 from "../components/Home/Section5";
import Section6 from "../components/Home/Section6";
import "./Home.css";

function Home() {
  return (
    <div className="Home">
      <Section1 />
      <Section3Carousel />
      <Section4 />
      <AboutUs />
      <FAQ />
      <Section5 />
      <Section6 />
    </div>
  );
}

export default Home;
